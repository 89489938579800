import styled from 'styled-components';
import { Grid } from '_constants';

// eslint-disable-next-line import/prefer-default-export
export const ImageBlock = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  @media (max-width: 855px) {
    justify-content: center;
  }
`;

import React, { useState } from 'react';
import { ReactComponent as CheckMarkRight } from 'assets/icons/checkmark-right.svg';
import { ReactComponent as CrossMark } from 'assets/icons/cross.svg';
import { ReactComponent as CheckMarkGrey } from 'assets/icons/checkmark-chosen.svg';
import { Image } from 'components';
import { CheckBoxOptions } from 'components/Polls/constants';

import {
  StyledBigImageCheckbox,
  ImageWrapper,
  CheckboxText,
  PercentOverlay,
  PercentColorBlock,
  OptionText,
  PercentColorBlockOverlay,
  ImageOverlay,
  CheckboxWrapper,
  ActionIconWrapper,
  CheckMark,
  FullScreenIcon,
} from './SmallImageCheckbox.styles';

const CheckboxIcon = {
  [CheckBoxOptions.RIGHT]: <CheckMarkRight />,
  [CheckBoxOptions.WRONG]: <CrossMark />,
  [CheckBoxOptions.SECONDARY_RIGHT]: <CheckMarkGrey />,
  [CheckBoxOptions.NORMAL]: <CheckMark />,
};

interface IProps {
  option?: string;
  value: string;
  img?: string;
  percent?: number | null;
  disable?: boolean;
  variant?: CheckBoxOptions;
}

const SmallImageCheckbox: React.FC<IProps> = ({ option, value, variant, img, disable, percent }) => {
  const actionIcon = CheckboxIcon[variant || CheckBoxOptions.NORMAL];
  const [previewVisible, setPreviewVisible] = useState(false);
  const openPreview = (event: React.UIEvent) => {
    event.preventDefault();
    setPreviewVisible(true);
  };
  const closePreview = () => {
    setPreviewVisible(false);
  };
  return (
    <CheckboxWrapper disabled={disable}>
      <StyledBigImageCheckbox disabled={disable} variant={variant} value={value} percent={typeof percent === 'number'}>
        {img && (
          <ImageWrapper>
            <Image
              original={img}
              thumbnail={img}
              alt="option image"
              preview
              beforeOpenPreview={closePreview}
              visible={previewVisible}
            />
            <FullScreenIcon onClick={openPreview} />
            <ImageOverlay />
          </ImageWrapper>
        )}
        {option ? (
          <CheckboxText level={3}>
            <OptionText>{option}</OptionText>
            {typeof percent === 'number' && <PercentColorBlock percent={percent} variant={variant} />}
          </CheckboxText>
        ) : (
          <PercentColorBlockOverlay>
            {typeof percent === 'number' && <PercentColorBlock percent={percent} variant={variant} />}
          </PercentColorBlockOverlay>
        )}
        {typeof percent === 'number' && <PercentOverlay>{percent}%</PercentOverlay>}
        <ActionIconWrapper>{actionIcon}</ActionIconWrapper>
      </StyledBigImageCheckbox>
    </CheckboxWrapper>
  );
};

export default SmallImageCheckbox;

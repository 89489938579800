import styled from 'styled-components';
import { Grid } from '_constants';

export const Card = styled.div`
  flex: auto;
  min-height: auto;
  margin: 24px auto;
  padding: 32px;
  min-width: 270px;
  max-width: 856px;
  overflow: hidden;
  height: fit-content;
  background-color: ${({ theme }) => theme.palette.white.primary};
  border: solid 1px ${({ theme }) => theme.palette.dark.dark_8};
  box-shadow: 0 2px 4px ${({ theme }) => theme.palette.shadow.light};
  border-radius: 8px;
  @media (max-width: ${Grid.XS}px) {
    margin: 24px 10px;
    padding: 24px 16px;
  }
`;

export default Card;

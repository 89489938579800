import { makeAutoObservable } from 'mobx';
import { IWebView, TWebViewAuth, TWebViewOther } from 'types/webViewTypes';
import { SDK } from 'utils';
import { UIStore } from 'store';
import { Locales } from '_constants';
import {
  DEFAULT_LOCALE,
  DEFAULT_LOCALIZATION_CODE,
  getLocaleByLocalizationCode,
  getLocalizationCodeByLocale,
  localeGuard,
  localizationCodeGuard,
} from '_constants/Locales';
import { getDataFromSDK, getDataFromURL } from './WebViewStore.utils';

class WebViewStore implements IWebView {
  private initialized = false;

  auth: TWebViewAuth;

  other: TWebViewOther;

  webEventRocks: boolean;

  networkError: boolean;

  get isReady(): boolean {
    return !!this.initialized;
  }

  get isSuccess(): boolean {
    return !!this.initialized && !!this.auth;
  }

  get isError(): boolean {
    return !!this.initialized && !this.auth;
  }

  constructor() {
    makeAutoObservable(this);

    this.auth = null;
    this.other = {};
    this.webEventRocks = true;
    this.networkError = false;
  }

  private setData = (data: IWebView) => {
    this.auth = { ...this.auth, ...data.auth };
    this.other = { ...this.other, ...data.other };
  };

  initDate = (): void => {
    this.clear();

    if (SDK.haveSDK) {
      this.webEventRocks = false;

      const dataFromSDK = getDataFromSDK();
      if (dataFromSDK) {
        this.setData(dataFromSDK);
      }
    }

    // NOTE: Данные из URL удаляются после редиректа роутера
    const dataFromUrl = getDataFromURL();
    if (dataFromUrl) {
      this.setData(dataFromUrl);
    }

    this.checkLocalization();
    if (this.auth?.lang) {
      UIStore.setLocale(this.auth.lang as Locales);
    }

    this.initialized = true;
  };

  checkLocalization = (): void => {
    if (!this.auth) {
      return;
    }

    // Проверка lang и locale на валидность
    if (this.auth?.lang && !localeGuard(this.auth.lang)) {
      this.auth.lang = DEFAULT_LOCALE;
    }
    if (this.auth?.locale && !localizationCodeGuard(this.auth?.locale)) {
      this.auth.locale = DEFAULT_LOCALIZATION_CODE;
    }

    // Добавление недостоющего поля локализации
    if (this.auth?.lang && !this.auth.locale) {
      this.auth.locale = getLocalizationCodeByLocale(this.auth.lang);
    }
    if (this.auth.locale && !this.auth.lang) {
      this.auth.lang = getLocaleByLocalizationCode(this.auth.locale);
    }
  };

  showNetworkErrorPlaceholder = (): void => {
    this.networkError = true;
  };

  clear = (): void => {
    this.initialized = false;

    this.auth = null;
    this.other = {};
    this.webEventRocks = true;
    this.networkError = false;
  };
}

export default WebViewStore;

import styled from 'styled-components';
import { ReactComponent as FullScreen } from 'assets/icons/fullscreen.svg';

export const FullScreenIcon = styled(FullScreen)`
  display: block;
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 9px;
  z-index: 8;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.mask.weaker};
  backdrop-filter: blur(10px);
  border-radius: 6px;
  cursor: pointer;
`;

export default { FullScreenIcon };

import { TCatalog, TCatalogChild, TCatalogLink } from 'types';
import { CatalogContentBlocksTypes, CatalogElementTypes, ViewOptions } from 'models/Catalogs/constants';

export type TContentType = {
  types: string[];
  viewOptions?: (string | null)[];
};

type Entries<T> = {
  [K in keyof T]: [key: K, value: T[K]];
}[keyof T][];

export type TCurrentGroupElements = {
  contentType?: [CatalogContentBlocksTypes, TContentType] | null;
  items: TCatalogChild[];
};
const typesWithViewOptions = [CatalogElementTypes.CATALOG, CatalogElementTypes.LINK];
const contentTypes: Record<CatalogContentBlocksTypes, TContentType> = {
  [CatalogContentBlocksTypes.CATALOG_IMG]: {
    types: [CatalogElementTypes.CATALOG],
    viewOptions: [ViewOptions.IMAGE],
  },
  [CatalogContentBlocksTypes.LINK_IMG]: {
    types: [CatalogElementTypes.LINK],
    viewOptions: [ViewOptions.IMAGE],
  },
};

export const groupElementsIntoContentBlocks = (
  elements: TCatalogChild[],
): [CatalogContentBlocksTypes | null, TCatalogChild[]][] => {
  const groupedElements: [CatalogContentBlocksTypes | null, TCatalogChild[]][] = [];
  const currentGroup: TCurrentGroupElements = {
    contentType: null,
    items: [],
  };
  const isSameType = (element: TCatalogChild, contentType?: TContentType | null) =>
    contentType &&
    contentType.types.includes(element.type) &&
    (!contentType.viewOptions ||
      (typesWithViewOptions.includes(element.type) &&
        contentType.viewOptions.includes((element as TCatalog | TCatalogLink).viewOptions)));
  const findContentType = (element: TCatalogChild): [CatalogContentBlocksTypes, TContentType] | undefined =>
    (Object.entries(contentTypes) as Entries<typeof contentTypes>).find(([, value]) => isSameType(element, value));

  for (let i = 0; i < elements.length; i += 1) {
    if (currentGroup.items.length && isSameType(elements[i], currentGroup.contentType?.[1])) {
      currentGroup.items.push(elements[i]);
    } else {
      if (currentGroup.items.length) {
        groupedElements.push([currentGroup.contentType?.[0] || null, currentGroup.items]);
      }
      const newContentTypeEntry = findContentType(elements[i]);
      currentGroup.contentType = newContentTypeEntry;
      currentGroup.items = [elements[i]];
    }
  }

  if (currentGroup.items.length > 0) {
    groupedElements.push([currentGroup.contentType?.[0] || null, currentGroup.items]);
  }
  return groupedElements;
};

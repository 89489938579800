import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ImageProps } from 'antd';
import { Spinner } from 'components';
import { ReactComponent as ErrorImage } from 'assets/images/placeholder-card.svg';
import { StyledImage, ImageOverlay, ErrorImageOverlay, SpinnerWrapper } from './Image.styles';

type TProps = {
  visible?: boolean;
  onError?: () => void;
  thumbnail: string;
  original?: string;
  preview?: boolean;
  onLoad?: () => void;
  beforeOpenPreview?: () => void;
  fallback?: ReactNode;
  onBeforeLoading?: () => void;
} & Omit<ImageProps, 'fallback'>;

const Image: React.FC<TProps> = ({
  placeholder = <Spinner />,
  fallback = <ErrorImage />,
  preview,
  onBeforeLoading,
  beforeOpenPreview,
  original,
  thumbnail,
  visible,
  onError,
  onLoad,
  ...rest
}) => {
  const [imageError, setImageError] = useState(false);
  const [initialized, init] = useState(false);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const placeholderWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!initialized) {
      onBeforeLoading?.();
      init(true);
      onLoad?.();
    }
  }, [onLoad, initialized, onBeforeLoading]);
  const handleError = () => {
    setImageError(true);
    if (onError) {
      onError();
    }
  };

  const onVisibleChange = (value: boolean) => {
    if (beforeOpenPreview) {
      beforeOpenPreview();
    }
    setVisiblePreview(value);
  };

  const previewConfig = {
    ...(visible !== undefined && {
      visible: visiblePreview,
      onVisibleChange,
    }),
    ...(!preview &&
      visible === undefined && {
        visible: false,
      }),
    mask: null,
    src: original || thumbnail,
  };

  const onClick = () => {
    if (preview) {
      onVisibleChange(!visiblePreview);
    }
  };
  useEffect(() => {
    if (visible !== undefined) {
      setVisiblePreview(visible);
    }
  }, [visible]);

  return (
    <ImageOverlay>
      {imageError ? (
        <ErrorImageOverlay>{fallback}</ErrorImageOverlay>
      ) : (
        <StyledImage
          onClick={onClick}
          src={thumbnail}
          preview={previewConfig}
          onError={handleError}
          placeholder={<SpinnerWrapper ref={placeholderWrapperRef}>{placeholder}</SpinnerWrapper>}
          {...rest}
        />
      )}
    </ImageOverlay>
  );
};
export default Image;

import React, { useState } from 'react';
import { Image } from 'components';
import { ReactComponent as CheckMarkRight } from 'assets/icons/checkmark-circle-right.svg';
import { ReactComponent as CrossMark } from 'assets/icons/cross-circle.svg';
import { ReactComponent as CheckMarkGrey } from 'assets/icons/checkmark-circle-chosen.svg';
import { CheckBoxOptions } from 'components/Polls/constants';
import {
  StyledImageCheckbox,
  ImageOverlay,
  CheckboxWrapper,
  CheckMark,
  ActionIconWrapper,
} from './ImageCheckbox.styles';
import { FullScreenIcon } from '../Polls.styles';

const CheckboxIcon = {
  [CheckBoxOptions.RIGHT]: <CheckMarkRight />,
  [CheckBoxOptions.WRONG]: <CrossMark />,
  [CheckBoxOptions.SECONDARY_RIGHT]: <CheckMarkGrey />,
  [CheckBoxOptions.NORMAL]: <CheckMark />,
};

interface IProps {
  value: string;
  variant: CheckBoxOptions;
  img?: string;
  disable?: boolean;
}

const ImageCheckbox: React.FC<IProps> = ({ disable, value, variant, img }) => {
  const actionIcon = CheckboxIcon[variant];
  const [previewVisible, setPreviewVisible] = useState(false);
  const openPreview = (event: React.UIEvent) => {
    event.preventDefault();
    setPreviewVisible(true);
  };
  const closePreview = () => {
    setPreviewVisible(false);
  };

  return (
    <CheckboxWrapper disabled={disable}>
      <StyledImageCheckbox disabled={disable} variant={variant} value={value}>
        {img && (
          <ImageOverlay>
            <Image
              thumbnail={img}
              original={img}
              alt="option image"
              preview
              visible={previewVisible}
              beforeOpenPreview={closePreview}
            />
            <FullScreenIcon onClick={openPreview} />
          </ImageOverlay>
        )}
        <ActionIconWrapper>{actionIcon}</ActionIconWrapper>
      </StyledImageCheckbox>
    </CheckboxWrapper>
  );
};

export default ImageCheckbox;

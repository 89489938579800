import { observer } from 'mobx-react';
import React, { lazy, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { UIStore } from 'store';
import translations from 'translations';
import { DEFAULT_LOCALE, RouteTemplates } from '_constants';
import {
  PrivateRoute,
  AliasApply,
  LandingRedirect,
  Spinner,
  ErrorBoundary,
  ShowOfflineNotification,
  ShowNotificationMessage,
  GlobalPlaceholder,
} from 'components';
import MainContent from './MainContent';

const Login = lazy(() => import('views/Login'));
const Layout = lazy(() => import('views/Layout'));

const App: React.FC = () => {
  return (
    <IntlProvider locale={UIStore.locale} messages={translations[UIStore.locale]} defaultLocale={DEFAULT_LOCALE}>
      <ThemeProvider theme={UIStore.theme}>
        <ErrorBoundary>
          <Router>
            <Suspense fallback={<Spinner fullscreen />}>
              <Switch>
                <Route path={RouteTemplates.alias}>
                  <AliasApply fallback={<Spinner fullscreen />}>
                    <GlobalPlaceholder>
                      <Switch>
                        <Route
                          path={[
                            RouteTemplates.authSignUp,
                            RouteTemplates.authSignIn,
                            RouteTemplates.authResetPassword,
                            RouteTemplates.authCallback,
                          ]}
                          component={Login}
                        />
                        <PrivateRoute>
                          <Layout>
                            <MainContent />
                          </Layout>
                        </PrivateRoute>
                      </Switch>
                    </GlobalPlaceholder>
                  </AliasApply>
                </Route>
                <Route exact path="/" component={LandingRedirect} />
              </Switch>
              <ShowNotificationMessage />
              <ShowOfflineNotification />
            </Suspense>
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default observer(App);

import { Grid } from '_constants';
import useMatchMedia from 'use-match-media-hook';

type TQueries = {
  isMobileMini: boolean;
  isMobileLarge: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopLarge: boolean;
};
const useMediaQueries = (): TQueries => {
  const [isMobileMini, isMobileLarge, isTabletMin, isTabletMax, isDesktop, isDesktopLarge] = useMatchMedia([
    `(max-width: ${Grid.XS}px)`,
    `(max-width: ${Grid.MD}px)`,
    `(min-width: ${Grid.MD + 1}px)`,
    `(max-width: ${Grid.LG}px)`,
    `(min-width: ${Grid.LG + 1}px)`,
    `(min-width: ${Grid.XXL}px)`,
  ]);
  return {
    isMobileMini,
    isMobileLarge,
    isTablet: isTabletMin && isTabletMax,
    isDesktop,
    isDesktopLarge,
  };
};

export default useMediaQueries;

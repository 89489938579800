export const CatalogElementTypes = {
  LINK: 'link',
  CATALOG: 'catalog',
  TEXT: 'text',
  FILE: 'file',
  ATTENDEE: 'attendee',
  GROUP: 'group',
  POLL: 'poll',
  AUDIO: 'audio',
  VIDEO: 'video',
  BOOKING: 'booking',
};

export enum CatalogContentBlocksTypes {
  CATALOG_IMG = 'catalogImage',
  LINK_IMG = 'linkImage',
}

export const ViewOptions = {
  IMAGE: 'imageOnly',
  TEXT: 'textOnly',
  MEDIA: 'textAndImage',
};

export const ATTENDEES_PER_PAGE = 20;

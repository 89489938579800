import styled from 'styled-components';
import { Grid } from '_constants';
import { ImageBlock } from '../CatalogContentBlock/CatalogContentBlock.styles';

export const CatalogElementWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
`;

export const GroupRowWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  & + & {
    margin-top: 0;
  }
`;

export const TextRowWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  & + & {
    margin-top: 0;
  }
`;

export const ImageRowWrapper = styled.div`
  box-sizing: content-box;
  display: flex;
  max-height: 250px;
  margin: 32px auto 0;
  max-width: calc(50% - 8px);
  flex-grow: 1;
  justify-content: center;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  @media (max-width: 855px) {
    max-width: 387px;
    flex: 1 1 100%;
  }
  ${ImageBlock} > & {
    margin: 0;
  }
`;

export const BookingElementWrapper = styled.div`
  width: 100%;
`;

export const BookingElementInnerWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 20px;
  }
  ${BookingElementWrapper} + ${BookingElementWrapper} & {
    margin-top: 16px;
  }
`;

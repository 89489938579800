import React, { useState } from 'react';
import { ReactComponent as CheckMarkCircleRight } from 'assets/icons/checkmark-circle-right.svg';
import { ReactComponent as CrossMarkCircle } from 'assets/icons/cross-circle.svg';
import { ReactComponent as CheckMarkCircleGrey } from 'assets/icons/checkmark-circle-chosen.svg';
import { Image } from 'components';
import { CheckBoxOptions } from 'components/Polls/constants';

import {
  StyledBigImageCheckbox,
  ImageWrapper,
  CheckboxText,
  OptionText,
  ImageOverlay,
  CheckboxWrapper,
  ImageWithIconOverlay,
  ActionIconWrapper,
  CheckMark,
  FullScreenIcon,
} from './BigImageCheckbox.styles';

const QuickCheckboxIcon = {
  [CheckBoxOptions.RIGHT]: <CheckMarkCircleRight />,
  [CheckBoxOptions.WRONG]: <CrossMarkCircle />,
  [CheckBoxOptions.SECONDARY_RIGHT]: <CheckMarkCircleGrey />,
  [CheckBoxOptions.NORMAL]: <CheckMark />,
};

interface IProps {
  option?: string;
  value: string;
  img?: string;
  percent?: number | null;
  disable?: boolean;
  variant?: CheckBoxOptions;
}

const BigImageCheckbox: React.FC<IProps> = ({ option, value, variant, img, disable, percent }) => {
  const actionIcon = QuickCheckboxIcon[variant || CheckBoxOptions.NORMAL];
  const [previewVisible, setPreviewVisible] = useState(false);
  const openImageModal = (event: React.UIEvent) => {
    event.preventDefault();
    setPreviewVisible(true);
  };
  const closePreview = () => {
    setPreviewVisible(false);
  };
  return (
    <CheckboxWrapper disabled={disable}>
      <StyledBigImageCheckbox disabled={disable} variant={variant} value={value} percent={typeof percent === 'number'}>
        {img && (
          <ImageWrapper>
            <ImageWithIconOverlay>
              <Image
                original={img}
                thumbnail={img}
                alt="option image"
                preview
                visible={previewVisible}
                beforeOpenPreview={closePreview}
              />
            </ImageWithIconOverlay>
            <FullScreenIcon onClick={openImageModal} />
            <ImageOverlay />
          </ImageWrapper>
        )}
        {option && (
          <CheckboxText level={3}>
            <OptionText>{option}</OptionText>
          </CheckboxText>
        )}
        <ActionIconWrapper>{actionIcon}</ActionIconWrapper>
      </StyledBigImageCheckbox>
    </CheckboxWrapper>
  );
};

export default BigImageCheckbox;

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Spinner from 'components/Spinner/Spinner';
import store from 'store';
import webViewStore from 'store/WebView';
import { ProblemPlaceholder } from 'components';

const WebViewProvider: React.FC = ({ children }) => {
  const { isReady, isSuccess, initDate, networkError } = webViewStore;
  const { isOnlineNetworkStatus } = store.UIStore;

  useEffect(() => {
    initDate();
  }, [initDate]);

  const onRefresh = () => {
    if (isOnlineNetworkStatus) {
      window.location.reload();
    }
  };

  if (!isReady) {
    return <Spinner fullscreen />;
  }

  const showChildren = isSuccess && !networkError;

  return showChildren ? <>{children}</> : <ProblemPlaceholder type="networkError" onRefresh={onRefresh} />;
};

export default observer(WebViewProvider);

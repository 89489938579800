import styled, { css } from 'styled-components';
import { Checkbox } from 'antd';
import { ReactComponent as CheckMarkSelect } from 'assets/icons/checkmark-circle-check.svg';
import { Grid } from '_constants';
import { TTheme } from 'types';
import { CheckBoxOptions } from 'components/Polls/constants';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { FullScreenIcon as FullScreenIconBase } from '../Polls.styles';

interface ICheckboxWrapper {
  disabled?: boolean;
}
interface IStyledImageCheckbox {
  disabled?: boolean;
  variant?: CheckBoxOptions;
}

const getStyledImageCheckboxColor = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return theme.palette.success.primary;
    case CheckBoxOptions.WRONG:
      return theme.palette.error.primary;
    default:
      return theme.palette.dark.main;
  }
};

const getStyledImageCheckboxBorder = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return `2px solid ${theme.palette.success.primary}`;
    case CheckBoxOptions.WRONG:
      return `2px solid ${theme.palette.error.primary}`;
    default:
      return `1px solid ${theme.palette.dark.dark_8}`;
  }
};

export const ImageOverloadByIconHover = styled.div`
  &::before {
    content: '';
    position: absolute;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.3s background-color;
  }
`;

export const CheckboxWrapper = styled.div<ICheckboxWrapper>`
  && {
    position: relative;
    display: flex;
    overflow: hidden;
    width: calc(50% - 10px);
    height: 162px;
    border-radius: 8px;
    user-select: none;

    ${({ disabled }) =>
      !disabled &&
      css`
        &:hover {
          ${ImageOverloadByIconHover}::before {
            background-color: ${({ theme }) => theme.palette.mask.weaker};
          }
        }
      `}

    @media (max-width: ${Grid.XS}px) {
      width: 100%;
      max-width: 288px;
    }
  }
`;

export const CheckMark = styled(CheckMarkSelect)`
  display: none;
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  rect {
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
  circle {
    fill: ${({ theme }) => theme.palette.white.primary};
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ActionIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
  width: 24px;
  height: 24px;
  border: 1.5px solid ${({ theme }) => theme.palette.dark.dark_24};
  background: ${({ theme }) => theme.palette.white.white_20};

  border-radius: 50%;
  box-shadow: 0 4px 12px 0 ${({ theme }) => theme.palette.shadow.weak};
`;

export const StyledImageCheckbox = styled(Checkbox)<IStyledImageCheckbox>`
  && {
    width: 100%;
    border-radius: 8px;
    color: ${({ theme, variant }) => getStyledImageCheckboxColor(theme, variant)};
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      border: ${({ theme, variant }) => getStyledImageCheckboxBorder(theme, variant)};
      border-radius: 8px;
      transition: background-color 0.4s;
    }

    &.ant-checkbox-wrapper-checked {
      ${CheckMark} {
        display: initial;
      }
    }

    &.ant-checkbox-wrapper-checked,
    &.ant-checkbox-wrapper-disabled {
      ${ActionIconWrapper} {
        border: none;
        box-shadow: none;
        background: none;
      }
    }

    &.ant-checkbox-wrapper-checked::before {
      ${({ theme, variant }) =>
        variant === CheckBoxOptions.NORMAL &&
        css`
          border: 2px solid ${theme.palette.primary.main};
        `};
    }

    & span:not(.ant-checkbox) {
      padding: 0;
    }

    & .ant-checkbox {
      position: absolute;

      & .ant-checkbox-inner {
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        transition: none;

        &::after {
          display: none;
        }
      }

      &.ant-checkbox-checked {
        &::after {
          display: none;
        }

        &:not(.ant-checkbox-disabled) .ant-checkbox-inner {
          &::before {
            opacity: 0;
          }
        }
      }

      & + span {
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      &.ant-checkbox-disabled + span {
        color: inherit;
      }
    }

    & .ant-image .ant-image-img {
      height: 162px;
      max-height: 162px;
      object-fit: contain;
    }
  }
`;

export const FullScreenIcon = styled(FullScreenIconBase)`
  @media (hover: hover) {
    display: block;
  }
`;

export const ImageOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;

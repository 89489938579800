import React from 'react';
import { isYoutubeVideoUrl } from 'utils';
import { TCatalogLink } from 'types';
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg';
import { ViewOptions } from 'models/Catalogs/constants';
import MediaLink from './MediaLink';
import YoutubeLink from './YoutubeLink';
import { LinkStyled, StyledTooltip } from './Link.styles';
import TextRow from '../TextRow';
import ImageRow from '../ImageRow';

interface IProps {
  link: TCatalogLink;
}

const Link: React.FC<IProps> = ({ link }) => {
  switch (link.viewOptions) {
    case ViewOptions.TEXT:
      return isYoutubeVideoUrl(link.value) ? (
        <YoutubeLink url={link.value} text={link.name || ''} />
      ) : (
        <LinkStyled href={link.value} target="_blank" rel="noreferrer">
          <TextRow content={link.name} icon={<LinkIcon />} />
        </LinkStyled>
      );
    case ViewOptions.MEDIA:
      return link?.textLogoImage?.url ? (
        <>
          {link?.name && link?.name.length > 90 ? (
            <StyledTooltip title={link.name} getPopupContainer={(trigger) => trigger}>
              <LinkStyled href={link.value} target="_blank" rel="noreferrer">
                <MediaLink imageSrc={link.textLogoImage?.url} text={link.name || ''} icon={<LinkIcon />} />
              </LinkStyled>
            </StyledTooltip>
          ) : (
            <LinkStyled href={link.value} target="_blank" rel="noreferrer">
              <MediaLink imageSrc={link.textLogoImage?.url} text={link.name || ''} icon={<LinkIcon />} />
            </LinkStyled>
          )}
        </>
      ) : null;
    case ViewOptions.IMAGE:
      return (
        <LinkStyled href={link.value} target="_blank" rel="noreferrer" $viewOptions={link.viewOptions}>
          <ImageRow imageSrc={link.fullLogoImage?.url} icon={<LinkIcon width={16} height={16} />} />
        </LinkStyled>
      );
    default:
      return null;
  }
};

export default Link;

import {
  IWebView,
  webViewBooleanParamGuard,
  webViewNumberParamGuard,
  webViewStringParamGuard,
} from 'types/webViewTypes';
import { SDK } from 'utils';

const getUrlParams = () => {
  try {
    const url = new URL(window.location.href);
    const params = Array.from(url.searchParams.entries());

    return params;
  } catch (error) {
    return null;
  }
};

export const getDataFromURL = (): IWebView | null => {
  const result: IWebView = {
    auth: {},
    other: {},
  };
  const params = getUrlParams();

  if (params && params.length) {
    params.forEach(([name, value]) => {
      if (webViewStringParamGuard(name)) {
        result.auth = { ...result.auth, [name]: value };
        return;
      }

      if (webViewNumberParamGuard(name) && !Number.isNaN(+value)) {
        result.auth = { ...result.auth, [name]: +value };
        return;
      }

      if (webViewBooleanParamGuard(name)) {
        result.auth = { ...result.auth, [name]: !!value };
        return;
      }

      result.other[name] = value;
    });

    return result;
  }

  return null;
};

export const getDataFromSDK = (): IWebView | null => {
  const result: IWebView = {
    auth: {},
    other: {},
  };

  if (SDK.haveSDK) {
    const eventId = SDK.getEventId();
    const token = eventId ? SDK.getAuthToken(eventId) : '';
    const userId = eventId ? SDK.getUserId(eventId) : 0;

    if (result.auth) {
      result.auth.authToken = token ? `PIN ${token}/${eventId}` : undefined;
      result.auth.embedded = true;
      result.auth.eventId = eventId;
      result.auth.userId = userId;

      // NOTE: Локаль из SDK
      // result.auth.locale = SDK.locale();
      // result.auth.lang = getLocaleByLocalizationCode(SDK.locale() ?? '');
    }
  }

  return result;
};

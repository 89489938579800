import React, { Children } from 'react';
import { CatalogContentBlocksTypes } from 'models/Catalogs/constants';
import { ImageBlock } from './CatalogContentBlock.styles';

interface ICatalogBlock {
  type: CatalogContentBlocksTypes | null;
}

const CatalogContentBlock: React.FC<ICatalogBlock> = ({ type, children }) => {
  if (
    Children.count(children) > 1 &&
    (type === CatalogContentBlocksTypes.CATALOG_IMG || type === CatalogContentBlocksTypes.LINK_IMG)
  ) {
    return <ImageBlock>{children}</ImageBlock>;
  }
  return <>{children}</>;
};

export default CatalogContentBlock;

import { api } from 'api';
import { TSignInResponse, TRestoreResponse, TUserAgreedResponse, TAuthCodeExchangeResponse } from 'types/authApiTypes';
import { TResponded } from 'types';

interface IAuthParams {
  login: string;
  password: string;
}

export const signIn = ({ login, password }: IAuthParams): Promise<TSignInResponse> =>
  api.publicRequest('post', 'api/web/tlrocks/auth', { json: { login, password } });

export const logout = (): Promise<TResponded<null>> => api.privateRequest('post', 'api/web/tlrocks/logout');

export const restorePassword = (login: string): Promise<TRestoreResponse> =>
  api.publicRequest('post', 'api/web/tlrocks/restorepassword', {
    json: { login },
  });

export const getPrivacyAgreement = (): Promise<TUserAgreedResponse> =>
  api.privateRequest('get', 'api/web/tlrocks/profile/privacy/agree');

export const acceptPrivacyAgreement = (date: Date): Promise<TResponded<null>> =>
  api.privateRequest('post', 'api/web/tlrocks/profile/privacy/agree', {
    json: { acceptDateTime: date },
  });

export const exchangeSsoAuthCode = (authCode: string): Promise<TResponded<TAuthCodeExchangeResponse>> =>
  api.publicRequest('post', 'api/web/tlrocks/sso/auth-code-exchange', {
    json: { authCode },
  });

export default {
  signIn,
  logout,
  restorePassword,
  getPrivacyAgreement,
  acceptPrivacyAgreement,
  exchangeSsoAuthCode,
};

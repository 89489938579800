import styled, { css } from 'styled-components';
import { Tooltip } from 'components';
import { Grid } from '_constants';
import { ViewOptions } from 'models/Catalogs/constants';

export const LinkStyled = styled.a<{ $viewOptions?: string }>`
  ${({ $viewOptions }) =>
    $viewOptions === ViewOptions.IMAGE &&
    css`
      width: 100%;
      height: 100%;
      display: flex;
    `}
  .ant-typography-single-line {
    @media (max-width: ${Grid.MD}px) {
      white-space: normal;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    width: 320px;
  }
`;

import React from 'react';
import { Image } from 'components';
import { Container, ImageOverlay, Text } from './MediaLink.styles';

interface Props {
  imageSrc?: string | null;
  text: string;
  icon?: React.ReactNode;
}

const MediaLink: React.FC<Props> = ({ imageSrc, text, icon }) =>
  imageSrc ? (
    <Container>
      <ImageOverlay>
        <Image thumbnail={imageSrc} />
      </ImageOverlay>
      <Text ellipsis={{ rows: 2 }}>{text}</Text>
      {icon}
    </Container>
  ) : null;

export default MediaLink;

export type NativeNavbarBtn = {
  type: number;
  setText: () => void;
  setImageType: (type: ButtonTypes) => void;
  setVisible: () => void;
  setEnabled: () => void;
};

export enum ButtonTypes {
  back = 0,
  close = 1,
  menu = 2,
  more = 3,
}

export enum OpeningEnvTypes {
  PUSH = 0,
  MODAL = 1,
  MENU = 2,
}

const haveSDK = !!window.EventiciousSDK;

const leftButton: NativeNavbarBtn | null = haveSDK ? window.EventiciousSDK.Buttons.Left : null;
const rightButton: NativeNavbarBtn | null = haveSDK ? window.EventiciousSDK.Buttons.Right : null;

const setNavBarTitle = (title: string): void => {
  if (haveSDK) {
    window.EventiciousSDK.setTitle(title);
  }
};

const setLeftButtonCallback = (callback: () => void): void => {
  if (window.EventiciousCallbacks) {
    window.EventiciousCallbacks.OnLeftButtonClicked = callback;
  }
};

const clearLeftButtonCallback = (): void => {
  if (window.EventiciousCallbacks) {
    window.EventiciousCallbacks.OnLeftButtonClicked = () => {
      window.EventiciousSDK?.close();
    };
  }
};

const setRightButtonCallback = (callback: () => void): void => {
  if (window.EventiciousCallbacks) {
    window.EventiciousCallbacks.OnRightButtonClicked = callback;
  }
};

const clearRightButtonCallback = (): void => {
  if (window.EventiciousCallbacks) {
    window.EventiciousCallbacks.OnRightButtonClicked = () => {};
  }
};

const openAttendee = (id: number): void => {
  if (haveSDK) {
    window.EventiciousSDK.openAppPath(`eventicious:///attendee/${id}`, {});
  }
};

const getEventId = (): number | undefined => {
  return haveSDK ? parseInt(window.EventiciousSDK.getCurrentConferenceId(), 10) : undefined;
};

const getAuthToken = (eventId: number): string | undefined => {
  return haveSDK ? window.EventiciousSDK.profilesManager.getProfile(eventId)?.token : undefined;
};

const getUserId = (eventId: number): number | undefined => {
  return haveSDK ? window.EventiciousSDK.profilesManager.getProfile(eventId)?.id : undefined;
};

const locale = (): string | undefined => {
  return haveSDK ? window.EventiciousSDK.locale() : undefined;
};

const getOpeningEnv = (): OpeningEnvTypes | undefined => {
  if (haveSDK) {
    return window.EventiciousSDK.getEnv() ?? 2;
  }
  return undefined;
};

const SDK = {
  haveSDK,
  leftButton,
  rightButton,
  setNavBarTitle,
  setLeftButtonCallback,
  clearLeftButtonCallback,
  setRightButtonCallback,
  clearRightButtonCallback,
  openAttendee,
  getEventId,
  getAuthToken,
  getUserId,
  locale,
  getOpeningEnv,
};

export default SDK;

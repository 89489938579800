import styled, { css } from 'styled-components';
import { Checkbox, Image as ImageBase } from 'antd';
import { Title } from 'components';
import { CheckBoxOptions } from 'components/Polls/constants';
import { ReactComponent as CheckMarkSelect } from 'assets/icons/checkmark-circle-check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { TTheme } from 'types';
import { ErrorImageOverlay } from 'components/Image/Image.styles';
import { MIXINS } from '_constants';
import { FullScreenIcon as FullScreenIconBase } from '../Polls.styles';

interface ICheckboxWrapper {
  disabled?: boolean;
}
interface IStyledBigImageCheckbox {
  disabled?: boolean;
  actionIcon?: string | null;
  percent?: boolean;
  variant?: CheckBoxOptions;
}

const getStyledBigImageCheckboxBorder = (theme: TTheme, variant?: CheckBoxOptions) => {
  switch (variant) {
    case CheckBoxOptions.RIGHT:
      return `2px solid ${theme.palette.success.primary}`;
    case CheckBoxOptions.WRONG:
      return `2px solid ${theme.palette.error.primary}`;
    default:
      return `1px solid ${theme.palette.dark.dark_8}`;
  }
};

export const OptionText = styled.div`
  position: relative;
  z-index: 3;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  z-index: 7;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.mask.weaker};
  opacity: 0;
  transition: 0.3s opacity;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
  min-height: 64px;
`;

export const ImageWithIconOverlay = styled.div`
  width: auto;
  height: auto;
  position: relative;
`;

export const CheckboxText = styled(Title)`
  &&&& {
    position: relative;
    font-weight: 500;
    word-break: break-word;
    transition: 0.3s background-color;
  }
`;

export const CheckboxWrapper = styled.div<ICheckboxWrapper>`
  && {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;
  }
`;

export const FullScreenIcon = styled(FullScreenIconBase)`
  @media (hover: hover) {
    display: block;
  }
`;

export const CheckMark = styled(CheckMarkSelect)`
  display: none;
  path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
  rect {
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
  circle {
    fill: ${({ theme }) => theme.palette.white.primary};
    stroke: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ActionIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 7;
  width: 24px;
  height: 24px;
  border: 1.5px solid ${({ theme }) => theme.palette.dark.dark_24};
  background: ${({ theme }) => theme.palette.white.white_20};
  border-radius: 50%;
  box-shadow: 0 4px 12px 0 ${({ theme }) => theme.palette.shadow.weak};
`;

export const StyledBigImageCheckbox = styled(Checkbox)<IStyledBigImageCheckbox>`
  && {
    width: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.dark.dark_4};
    color: ${({ theme }) => theme.palette.dark.main};
    font-weight: 500;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    line-height: 24px;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      border: ${({ theme, variant }) => getStyledBigImageCheckboxBorder(theme, variant)};
      border-radius: 8px;
    }
    &&&& {
      ${CheckboxText} {
        padding: 18px 20px;
        color: ${({ theme }) => theme.palette.dark.main};
      }
    }

    &:not(.ant-checkbox-wrapper-disabled):hover {
      ${CheckboxText} {
        background-color: ${({ theme }) => theme.palette.dark.dark_8};
      }
    }

    &.ant-checkbox-wrapper::after {
      display: none;
    }

    &.ant-checkbox-wrapper-disabled {
      ${ActionIconWrapper} {
        border: none;
        box-shadow: none;
        background: transparent;
      }
    }

    &.ant-checkbox-wrapper-checked {
      ${CheckMark} {
        display: initial;
      }
      ${ActionIconWrapper} {
        border: none;
        box-shadow: none;
      }
      &::before {
        ${({ theme, variant }) =>
          variant === CheckBoxOptions.NORMAL &&
          css`
            border: 2px solid ${theme.palette.primary.main};
          `};
      }
    }

    & span:not(.ant-checkbox) {
      padding: 0;
    }

    & .ant-checkbox {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 5;
      width: 24px;
      height: 24px;
      margin: auto;

      & .ant-checkbox-inner {
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        background-color: transparent;
        transition: none;

        &::after {
          display: none;
        }
      }

      &.ant-checkbox-checked {
        &::after {
          display: none;
        }

        &:not(.ant-checkbox-disabled) .ant-checkbox-inner {
          &::before {
            opacity: 0;
          }
        }
      }

      & + span {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }

      &.ant-checkbox-disabled + span {
        color: inherit;
      }
    }

    & ${ErrorImageOverlay} {
      position: relative;
      min-width: 114px;
      ${MIXINS.ratio16x9}

      svg {
        width: 50%;
      }
    }

    & .ant-image {
      & .ant-image-img {
        height: 336px;
        max-height: 336px;
        object-fit: contain;
      }
    }
    ${({ disabled }) =>
      disabled
        ? css`
            ${ImageOverlay} {
              cursor: not-allowed;
            }
          `
        : css`
            :hover {
              ${ImageOverlay} {
                opacity: 1;
              }
            }
          `};

    & .ant-modal-wrap {
      cursor: auto;
    }
  }
`;

export const ImagePreview = styled(ImageBase)`
  &&& {
    cursor: auto;
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;

import React, { useState, useEffect } from 'react';
import parse, { HTMLReactParserOptions, DOMNode, Element } from 'html-react-parser';
import { isTalentRocksApp } from 'env';
import { Image } from 'components';
import { Wrapper, StyledText, RichTextImageWrapper, FullScreenIcon } from './RichText.styles';

interface IReachText {
  content: string;
  onFinishLoading?: () => void;
  onStartLoading?: () => void;
}

const ALLOWED_INLINE_STYLES = ['text-decoration: underline', 'text-decoration: line-through'];

const ReachText: React.FC<IReachText> = ({ content, onFinishLoading, onStartLoading }) => {
  const [imageSourcesAreLoading, setImageSourcesAreLoading] = useState<string[]>([]);

  const addImageSrc = (src: string) => {
    setImageSourcesAreLoading((prev) => {
      return prev.find((source) => source === src) ? prev : [...prev, src];
    });
  };

  const imageLoadingIsFinished = (src: string) => {
    setImageSourcesAreLoading(imageSourcesAreLoading.filter((imageId) => imageId !== src));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => onFinishLoading?.(), []);

  useEffect(() => {
    if (imageSourcesAreLoading.length) {
      onStartLoading?.();
    } else {
      onFinishLoading?.();
    }
  }, [imageSourcesAreLoading.length, onFinishLoading, onStartLoading]);

  // Проверка является ли элемент единственным в абзаце - для добавления отступов заголовков
  const isHeaderType = (domNode: Element) => {
    const { children, parent } = domNode as Element;
    if (children.length === 1) {
      for (let tempParent = parent as Element; tempParent; tempParent = tempParent.parent as Element) {
        if (tempParent.children.length !== 1 || tempParent.name === 'span') {
          break;
        }
        if (tempParent.name === 'p' && tempParent.nextSibling) {
          return true;
        }
      }
    }
    return false;
  };

  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      const { name, children, attribs } = domNode as Element;
      const child = children && children[0];
      const childName = (child as Element)?.name;

      if (name === 'a' && childName === 'img') {
        const source = (child as Element)?.attribs?.src;
        try {
          const childLink = new URL(source);
          const parentLink = new URL(attribs?.href);
          if (parentLink.host === childLink.host) {
            return (
              <RichTextImageWrapper>
                <Image
                  original={source}
                  thumbnail={source}
                  alt="image"
                  preview
                  onError={() => imageLoadingIsFinished(source)}
                  onLoad={() => imageLoadingIsFinished(source)}
                  onBeforeLoading={() => addImageSrc(source)}
                />
                <FullScreenIcon />
              </RichTextImageWrapper>
            );
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (name === 'a' && childName !== 'img' && window.EventiciousSDK) {
        const parent = domNode?.parent;
        if ((parent as Element)?.name !== 'img') {
          const link = domNode as Element;
          const { href, target } = link.attribs;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          link.attribs.onClick = () => {
            if (target === '_blank') {
              window.EventiciousSDK.openAppPath(href, { external: true });
            } else {
              window.EventiciousSDK.openAppPath(href, { external: false });
            }
          };
          delete link.attribs.href;
          return link;
        }
      }

      if (name === 'img') {
        const source = (domNode as Element)?.attribs?.src;
        const parent = domNode?.parent;

        if ((parent as Element)?.name !== 'a') {
          return (
            <RichTextImageWrapper>
              <Image
                original={source}
                thumbnail={source}
                preview
                onLoad={() => imageLoadingIsFinished(source)}
                onError={() => imageLoadingIsFinished(source)}
                onBeforeLoading={() => addImageSrc(source)}
              />
              <FullScreenIcon />
            </RichTextImageWrapper>
          );
        }
      }

      if (isTalentRocksApp) {
        if (name === 'span' && attribs?.style) {
          // HACK: Удаляем инлайн-стили, не соответствующие дизайн-системе
          attribs.style = ALLOWED_INLINE_STYLES.filter((line) => attribs.style.includes(line)).join(';');
        }

        if (name === 'span' && attribs?.class === 'textlarge') {
          if (isHeaderType(domNode as Element)) {
            attribs.style += 'display: block; margin-bottom:32px;';
          }
        }

        if (name === 'span' && attribs?.class === 'textheader') {
          if (isHeaderType(domNode as Element)) {
            attribs.style += 'display: block; margin-bottom:16px;';
          }
        }
      }

      return null;
    },
  };
  return (
    <Wrapper>
      <StyledText>{parse(content, options)}</StyledText>
    </Wrapper>
  );
};

export default ReachText;

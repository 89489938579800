/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { flow, getRoot, types } from 'mobx-state-tree';
import { autorun } from 'mobx';
import { api, authApi, StatusCodes } from 'api';
import { sendAnalyticsEvent } from 'utils/analytics';
import {
  AuthorizationStatus,
  ContactType,
  LimitStatus,
  RestoreStatus,
  TRestoreResponse,
  TSignInResponse,
  TUserAgreedResponse,
  TAuthCodeExchangeResponse,
} from 'types/authApiTypes';
import { TResponded, TStore } from 'types';

const authTokenFromUrl = new URLSearchParams(window.location.search).get('authToken');

const Auth = types
  .model({
    token: authTokenFromUrl || '',
    autoLogin: '',
  })
  .views((self) => ({
    get authUIStore() {
      return getRoot<TStore>(self).UIStore.auth;
    },
    get regUIStore() {
      return getRoot<TStore>(self).UIStore.registration;
    },
  }))
  .actions((self) => ({
    setToken: (token: string) => {
      self.token = token;
    },
    setAutoLogin: (autoLogin: string) => {
      self.autoLogin = autoLogin;
    },
    clearToken: () => {
      self.token = '';
    },
    checkPrivacyPolicy: flow(function* checkPrivacyPolicy() {
      self.authUIStore.setFetching(true);
      const { data, hasError }: TUserAgreedResponse = yield authApi.getPrivacyAgreement();
      self.authUIStore.setFetching(false);
      return !!data?.agreement?.acceptanceDate && !hasError;
    }),
    acceptPrivacyAgreement: flow(function* acceptPrivacyAgreement(date: Date) {
      const { hasError }: TResponded = yield authApi.acceptPrivacyAgreement(date);
      return !hasError;
    }),
  }))
  .actions((self) => ({
    ssoLogin: flow(function* ssoLogin(authCode: string) {
      self.authUIStore.setFetching(true);
      const { data, hasError }: TAuthCodeExchangeResponse = yield authApi.exchangeSsoAuthCode(authCode);
      self.authUIStore.setFetching(false);
      const token = data?.token.accessToken ?? '';
      self.setToken(token);
      sendAnalyticsEvent('auth_logged_in', { source: 'start', method: 'sso' });
      return !hasError;
    }),
    checkLoginForm: flow(function* checkLoginForm() {
      self.authUIStore.setFetching(true);
      const { data, response }: TSignInResponse = yield authApi.signIn({
        login: self.authUIStore.login,
        password: self.authUIStore.password,
      });
      self.authUIStore.setLoginFormValid(
        data?.status === AuthorizationStatus.REGISTERED || data?.status === AuthorizationStatus.PREMODERATION,
      );
      self.authUIStore.setIsPremoderation(data?.status === AuthorizationStatus.PREMODERATION);
      const token = data?.token ?? '';
      api.token = token;
      self.autoLogin = data?.autoLogin ? data.autoLogin : '';
      self.authUIStore.setCheckPrivacyPolicyToken(token);
      self.authUIStore.setFetching(false);
      return {
        isValid: self.authUIStore.isLoginFormValid,
        isBlockedByEmail: data?.status === AuthorizationStatus.BLOCKED_AUTH_BY_EMAIL,
        isBlockedByPhone: data?.status === AuthorizationStatus.BLOCKED_AUTH_BY_PHONE,
        isUnauthorized: response?.status === StatusCodes.Unauthorized,
      };
    }),
    exitAccount: flow(function* exitAccount() {
      self.authUIStore.setFetching(true);
      const { response }: TResponded = yield authApi.logout();
      self.authUIStore.setFetching(false);
      if (response?.ok || response?.status === StatusCodes.Unauthorized) {
        self.clearToken();
        self.regUIStore.setCurrentStep(1);
        return true;
      }
      return false;
    }),
    checkRecoveryForm: flow(function* checkRecoveryForm() {
      self.authUIStore.setFetching(true);
      const { data }: TRestoreResponse = yield authApi.restorePassword(self.authUIStore.login);
      const isSMSLimitExceeded =
        data?.limitStatus === LimitStatus.SMS_LIMIT_EXCEEDED && data?.sendType === ContactType.SMS;
      const isSMSLimitExceededSendByEmail = !!(
        data?.limitStatus === LimitStatus.SMS_LIMIT_EXCEEDED &&
        data?.sendType === ContactType.EMAIL &&
        data?.sentTo
      );
      const isRecoveryWasSuccessful =
        (data?.status === RestoreStatus.SUCCESS && !isSMSLimitExceeded) || isSMSLimitExceededSendByEmail;
      self.authUIStore.setRecoveryFormSent(isRecoveryWasSuccessful);
      self.authUIStore.setRecoveryFormValid(data?.status === RestoreStatus.SUCCESS);
      self.authUIStore.setLoginType(data?.sendType ?? null);
      self.authUIStore.setSentTo(data?.sentTo ?? null);
      self.authUIStore.setFetching(false);
      return {
        incorrectFormat: data?.code ?? null,
        userNotFound: data?.status === RestoreStatus.NOT_FOUND,
        blockedForSeconds: data?.blockedForSeconds ?? null,
        isBlockedByEmail: data?.status === RestoreStatus.BLOCKED_RESTORE_BY_EMAIL,
        isBlockedByPhone: data?.status === RestoreStatus.BLOCKED_RESTORE_BY_PHONE,
        isSMSLimitExceeded,
        isSMSLimitExceededSendByEmail,
        sendType: data?.sendType ?? null,
      };
    }),
    onLogin: () => {
      self.setToken(self.authUIStore.checkPrivacyPolicyToken);
      self.authUIStore.clearCheckPrivacyPolicyToken();
      self.authUIStore.resetFormFields();
      sendAnalyticsEvent('auth_logged_in', { source: 'start' });
    },
    afterCreate() {
      autorun(() => {
        api.token = self.token;
      });
    },
  }));

export default Auth;

import { Image } from 'antd';
import styled from 'styled-components';

export const StyledImage = styled(Image)`
  width: 100%;
`;

export const SpinnerWrapper = styled.div``;

export const ImageOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  & .ant-image {
    width: 100%;
  }
  & .ant-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;

    & .ant-image-img {
      object-fit: none;
    }
  }
`;

export const ErrorImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.hoverBackground};
`;

import { useCallback, useMemo } from 'react';
import { ExtractRouteParams } from 'react-router';
import { useRouteMatch, generatePath, useHistory } from 'react-router-dom';
import { compile } from 'utils/pathToRegExp';
import { Routes, RouteTemplates } from '_constants';
import { generatePathCatalogParam } from 'utils';
import { TStage } from 'types';
import { isTalentRocksApp } from 'env';

type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

type Stringify<T> = {
  [K in keyof T]: string | undefined;
};

interface IOptions {
  replace: boolean;
  state?: unknown;
}

const defaultOptions: IOptions = {
  replace: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAppNavigator = () => {
  type TRouteParams = Stringify<
    UnionToIntersection<ExtractRouteParams<Exclude<Routes, Routes.CATALOG>>> & {
      catalog?: string;
    }
  >;
  const { params } = useRouteMatch<TRouteParams>();
  const {
    alias,
    catalog,
    courseId,
    pollId,
    stageId,
    screenId,
    coursesStatus,
    scormId,
    stepId,
    groupId,
    prizeId,
    bookingId,
    taskId,
    frameSlug,
    learningTracksStatus,
    trackId,
    trackStepId,
  } = params;
  const history = useHistory();

  const goTo = useCallback(
    (path: string, options: IOptions) => {
      if (options.replace) {
        history.replace(path, options.state);
        return;
      }

      history.push(path, options.state);
    },
    [history],
  );

  const goToAlias = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.alias, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToCoursesSection = useCallback(
    (status: string, options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.coursesStatus, {
          alias,
          coursesStatus: status,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToAuthSignIn = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.authSignIn, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToAuthSignUp = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !stepId) return;
      goTo(
        generatePath(RouteTemplates.authSignUp, {
          alias,
          stepId: id,
        }),
        options,
      );
    },
    [alias, stepId, goTo],
  );

  const goToAuthResetPassword = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.authResetPassword, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToAllCourses = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.allCourses, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToCourse = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.course, {
          alias,
          courseId: id,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToCatalog = useCallback(
    (targetStageId: number, targetCatalogIds: number[], options: IOptions = defaultOptions) => {
      if (!alias || !courseId) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.catalog, { validate: false })({
        alias,
        courseId,
        stageId: targetStageId,
        catalog: catalogPath,
      });

      goTo(path, options);
    },
    [alias, courseId, goTo],
  );

  const goToScorm = useCallback(
    (targetStageId: number, targetScormId, options: IOptions = defaultOptions) => {
      if (!alias || !courseId) return;
      goTo(
        generatePath(RouteTemplates.scorm, {
          alias,
          courseId,
          stageId: targetStageId,
          scormId: targetScormId,
        }),
        options,
      );
    },
    [alias, courseId, goTo],
  );

  const goToStageTask = useCallback(
    (targetStageId: number, targetTaskId, options: IOptions = defaultOptions) => {
      if (!alias || !courseId) return;
      goTo(
        generatePath(RouteTemplates.stageTask, {
          alias,
          courseId,
          stageId: targetStageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, courseId, goTo],
  );

  const goToStageTaskFinished = useCallback(
    (targetTaskId: number, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId) return;
      goTo(
        generatePath(RouteTemplates.stageTaskFinished, {
          alias,
          courseId,
          stageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, courseId, stageId, goTo],
  );

  const goToStageTaskAnswers = useCallback(
    (targetTaskId: number, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId) return;
      goTo(
        generatePath(RouteTemplates.stageTaskAnswers, {
          alias,
          courseId,
          stageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, courseId, stageId, goTo],
  );

  const goToStageTaskReviewerComment = useCallback(
    (targetTaskId: number, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId) return;
      goTo(
        generatePath(RouteTemplates.stageTaskReviewerComment, {
          alias,
          courseId,
          stageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, courseId, stageId, goTo],
  );

  const goToPoll = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId || !catalog) return;
      const path = compile(RouteTemplates.poll, { validate: false })({
        alias,
        courseId,
        stageId,
        catalog,
        pollId: id,
      });
      goTo(path, options);
    },
    [alias, catalog, courseId, goTo, stageId],
  );

  const goToPollScreen = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId || !catalog || !pollId) return;
      const path = compile(RouteTemplates.pollScreen, { validate: false })({
        alias,
        courseId,
        stageId,
        catalog,
        pollId,
        screenId: id,
      });
      goTo(path, options);
    },
    [alias, catalog, courseId, goTo, pollId, stageId],
  );

  const goToPollFinish = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId || !catalog || !pollId) return;
      const path = compile(RouteTemplates.pollFinishScreen, { validate: false })({
        alias,
        courseId,
        stageId,
        catalog,
        pollId,
      });
      goTo(path, options);
    },
    [alias, catalog, courseId, goTo, pollId, stageId],
  );

  const goToPollResult = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId || !catalog || !pollId) return;
      const path = compile(RouteTemplates.pollResultScreen, { validate: false })({
        alias,
        courseId,
        stageId,
        catalog,
        pollId,
      });
      goTo(path, options);
    },
    [alias, catalog, courseId, goTo, pollId, stageId],
  );

  const myTeamPath = useMemo(() => {
    if (!alias) return null;
    return compile(RouteTemplates.myTeam)({
      alias,
    });
  }, [alias]);

  const goToMyTeam = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias) return;
      const path = compile(RouteTemplates.myTeam)({
        alias,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const myTeamActivityPath = useMemo(() => {
    if (!alias) return '';
    return compile(RouteTemplates.myTeamActivity)({
      alias,
    });
  }, [alias]);

  const goToMyTeamActivity = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias) return;
      const path = compile(RouteTemplates.myTeamActivity)({
        alias,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const goToGamificationRatings = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.gamification, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGamificationRatingsDetails = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.gamificationRatingsDetails, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGamificationRules = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.gamificationRules, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGamificationPrizes = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.gamificationPrizes, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGamificationPrize = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.gamificationPrize, {
          alias,
          prizeId: id,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGroupGamificationPrizes = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.groupGamificationPrizes, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGroupGamificationPrize = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.groupGamificationPrize, {
          alias,
          prizeId: id,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGroupGamificationRatings = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.groupGamification, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGroupGamificationRatingsDetails = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.groupGamificationRatingsDetails, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToGroupGamificationRules = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias && isTalentRocksApp) return;
      goTo(
        generatePath(RouteTemplates.groupGamificationRules, {
          alias,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const gamificationRulesPath = useMemo(() => {
    if (!alias && isTalentRocksApp) return '';
    return compile(RouteTemplates.gamificationRules)({ alias });
  }, [alias]);

  const groupGamificationRulesPath = useMemo(() => {
    if (!alias && isTalentRocksApp) return '';
    return compile(RouteTemplates.groupGamificationRules)({ alias });
  }, [alias]);

  const getMyTeamGroupPath = useCallback(
    (id: number) => {
      return compile(RouteTemplates.myTeamGroup)({
        alias,
        groupId: id,
      });
    },
    [alias],
  );

  const getMyTeamGroupActivityPath = useCallback(
    (id: number) => {
      return compile(RouteTemplates.myTeamGroupActivity)({
        alias,
        groupId: id,
      });
    },
    [alias],
  );

  const getMyTeamGroupCoursePath = useCallback(
    (id: number) => {
      return compile(RouteTemplates.myTeamGroupCourse)({
        alias,
        groupId,
        courseId: id,
      });
    },
    [alias, groupId],
  );

  const getMyTeamGroupTracksPath = useCallback(() => {
    return compile(RouteTemplates.myTeamGroupTracks)({
      alias,
      groupId,
    });
  }, [alias, groupId]);

  const getMyTeamGroupTrackPath = useCallback(
    (id: string) => {
      return compile(RouteTemplates.myTeamGroupTrack)({
        alias,
        groupId,
        trackId: id,
      });
    },
    [alias, groupId],
  );

  const getMyTeamGroupTrackCoursePath = useCallback(
    (id: number) => {
      return compile(RouteTemplates.myTeamGroupTrackCourse)({
        alias,
        groupId,
        trackId,
        courseId: id,
      });
    },
    [alias, groupId, trackId],
  );

  const getMyTeamGroupCoursesPath = useCallback(() => {
    return compile(RouteTemplates.myTeamGroupCourses)({
      alias,
      groupId,
    });
  }, [alias, groupId]);

  const goToMyTeamGroup = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias) return;
      const path = compile(RouteTemplates.myTeamGroup, { validate: false })({
        alias,
        groupId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );
  const goToMyPolls = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myPolls, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyPollsActive = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myPollsActive, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyPollsFinished = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myPollsFinished, {
        alias,
      }),
    );
  }, [alias, history]);

  // poll from menu
  const goToMenuPoll = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.menuPoll, { validate: false })({
        alias,
        pollId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const goToMyTeamGroupCourses = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !groupId) return;
      const path = compile(RouteTemplates.myTeamGroupCourses, { validate: false })({
        alias,
        groupId,
      });
      goTo(path, options);
    },
    [alias, groupId, goTo],
  );

  const goToMyTeamGroupCourse = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !groupId) return;
      const path = compile(RouteTemplates.myTeamGroupCourse, { validate: false })({
        alias,
        groupId,
        courseId: id,
      });
      goTo(path, options);
    },
    [alias, groupId, goTo],
  );

  const goToMyTeamGroupTracks = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !groupId) return;
      const path = compile(RouteTemplates.myTeamGroupTracks, { validate: false })({
        alias,
        groupId,
      });
      goTo(path, options);
    },
    [alias, groupId, goTo],
  );

  const goToMyTeamGroupTrack = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !groupId) return;
      const path = compile(RouteTemplates.myTeamGroupTrack, { validate: false })({
        alias,
        groupId,
        trackId: id,
      });
      goTo(path, options);
    },
    [alias, groupId, goTo],
  );

  const goToMyTeamGroupActivity = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !groupId) return;
      const path = compile(RouteTemplates.myTeamGroupActivity, { validate: false })({
        alias,
        groupId,
      });
      goTo(path, options);
    },
    [alias, groupId, goTo],
  );
  const goToMenuPollScreen = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.menuPollScreen)({
        alias,
        pollId,
        screenId: id,
      });
      goTo(path, options);
    },
    [alias, goTo, pollId],
  );
  const goToMenuPollFinish = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !pollId) return;
      const path = compile(RouteTemplates.menuPollFinishScreen, { validate: false })({
        alias,
        pollId,
      });
      goTo(path, options);
    },
    [alias, goTo, pollId],
  );
  const goToMenuPollResult = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !pollId) return;
      const path = compile(RouteTemplates.menuPollResultScreen)({
        alias,
        pollId,
      });
      goTo(path, options);
    },
    [alias, goTo, pollId],
  );

  // poll from my polls
  const goToMyPoll = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.myPoll, { validate: false })({
        alias,
        pollId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );
  const goToMyPollScreen = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.myPollScreen)({
        alias,
        pollId,
        screenId: id,
      });
      goTo(path, options);
    },
    [alias, goTo, pollId],
  );
  const goToMyPollFinish = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !pollId) return;
      const path = compile(RouteTemplates.myPollFinishScreen, { validate: false })({
        alias,
        pollId,
      });
      goTo(path, options);
    },
    [alias, goTo, pollId],
  );
  const goToMyPollResult = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !pollId) return;
      const path = compile(RouteTemplates.myPollResultScreen)({
        alias,
        pollId,
      });
      goTo(path, options);
    },
    [alias, goTo, pollId],
  );

  const goToMenuCatalog = useCallback(
    (targetCatalogIds: number[], options: IOptions = defaultOptions) => {
      if (!alias || !catalog) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalog, { validate: false })({
        alias,
        catalog: catalogPath,
      });

      goTo(path, options);
    },
    [alias, catalog, goTo],
  );

  //  poll from catalog in menu
  const goToMenuCatalogPoll = useCallback(
    (targetCatalogIds: number[], id: number, options: IOptions = defaultOptions) => {
      if (!alias || !catalog || !id) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalogPoll, { validate: false })({
        alias,
        catalog: catalogPath,
        pollId: id,
      });

      goTo(path, options);
    },
    [alias, catalog, goTo],
  );
  const goToMenuCatalogPollScreen = useCallback(
    (targetCatalogIds: number[], id: number, options: IOptions = defaultOptions) => {
      if (!alias || !catalog || !id) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalogPollScreen, { validate: false })({
        alias,
        catalog: catalogPath,
        pollId,
        screenId: id,
      });
      goTo(path, options);
    },
    [alias, goTo, catalog, pollId],
  );
  const goToMenuCatalogPollFinish = useCallback(
    (targetCatalogIds: number[], options: IOptions = defaultOptions) => {
      if (!alias || !catalog || !pollId) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalogPollFinishScreen, { validate: false })({
        alias,
        catalog: catalogPath,
        pollId,
      });
      goTo(path, options);
    },
    [alias, goTo, catalog, pollId],
  );
  const goToMenuCatalogPollResult = useCallback(
    (targetCatalogIds: number[], options: IOptions = defaultOptions) => {
      if (!alias || !catalog || !pollId) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalogPollResultScreen, { validate: false })({
        alias,
        catalog: catalogPath,
        pollId,
      });
      goTo(path, options);
    },
    [alias, catalog, goTo, pollId],
  );
  const goToMyBookings = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myBookings, {
        alias,
      }),
    );
  }, [alias, history]);
  const goToMyBookingCurrent = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myBookingsCurrent, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyBookingAvailable = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myBookingsAvailable, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyBooking = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.myBooking, { validate: false })({
        alias,
        bookingId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const goToMyBookingRegistrations = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.myBookingRegistrations, { validate: false })({
        alias,
        bookingId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const goToMenuCatalogBooking = useCallback(
    (targetCatalogIds: number[], id: string, options: IOptions = defaultOptions) => {
      if (!alias || !catalog || !id) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalogBooking, { validate: false })({
        alias,
        catalog: catalogPath,
        bookingId: id,
      });

      goTo(path, options);
    },
    [alias, catalog, goTo],
  );

  const goToMenuCatalogBookingRegistrations = useCallback(
    (targetCatalogIds: number[], id: string, options: IOptions = defaultOptions) => {
      if (!alias || !catalog || !id) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.menuCatalogBookingRegistrations, { validate: false })({
        alias,
        catalog: catalogPath,
        bookingId: id,
      });

      goTo(path, options);
    },
    [alias, catalog, goTo],
  );

  const goToCourseBooking = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId || !catalog) return;
      const path = compile(RouteTemplates.courseBooking, { validate: false })({
        alias,
        courseId,
        stageId,
        catalog,
        bookingId: id,
      });
      goTo(path, options);
    },
    [alias, catalog, courseId, goTo, stageId],
  );

  const goToCourseBookingRegistrations = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || !stageId || !catalog) return;
      const path = compile(RouteTemplates.courseBookingRegistrations, { validate: false })({
        alias,
        courseId,
        stageId,
        catalog,
        bookingId: id,
      });
      goTo(path, options);
    },
    [alias, catalog, courseId, goTo, stageId],
  );

  const goToLearningTracksSection = useCallback(
    (status: string, options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.myLearningTracksStatus, {
          alias,
          learningTracksStatus: status,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToLearningTrack = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrack, {
          alias,
          trackId: id,
        }),
        options,
      );
    },
    [alias, goTo],
  );

  const goToLearningTrackCourse = useCallback(
    (id: number, trackStepUUId: string, options: IOptions = defaultOptions) => {
      if (!alias || !trackId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackCourse, {
          alias,
          trackId,
          trackStepId: trackStepUUId,
          courseId: id,
        }),
        options,
      );
    },
    [alias, goTo, trackId],
  );

  const goToLearningTrackStage = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackStage, {
          alias,
          trackId,
          trackStepId,
          courseId,
          stageId: id,
        }),
        options,
      );
    },
    [alias, trackId, trackStepId, courseId, goTo],
  );

  const goToLearningTrackCatalog = useCallback(
    (targetStageId: number, targetCatalogIds: number[], options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId) return;
      const catalogPath = generatePathCatalogParam(targetCatalogIds);
      const path = compile(RouteTemplates.myLearningTrackCatalog, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId: targetStageId,
        catalog: catalogPath,
      });

      goTo(path, options);
    },
    [alias, trackId, trackStepId, courseId, goTo],
  );

  const goToLearningTrackScorm = useCallback(
    (targetStageId: number, targetScormId, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackScorm, {
          alias,
          trackId,
          trackStepId,
          courseId,
          stageId: targetStageId,
          scormId: targetScormId,
        }),
        options,
      );
    },
    [alias, trackId, trackStepId, courseId, goTo],
  );

  const goToLearningTrackTask = useCallback(
    (targetStageId: number, targetTaskId, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackTask, {
          alias,
          trackId,
          trackStepId,
          courseId,
          stageId: targetStageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, trackId, trackStepId, courseId, goTo],
  );

  const goToLearningTrackTaskFinished = useCallback(
    (targetTaskId, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackTaskFinished, {
          alias,
          trackId,
          trackStepId,
          courseId,
          stageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, trackId, trackStepId, courseId, stageId, goTo],
  );

  const goToLearningTrackTaskAnswers = useCallback(
    (targetTaskId, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackTaskAnswers, {
          alias,
          trackId,
          trackStepId,
          courseId,
          stageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, trackId, trackStepId, courseId, stageId, goTo],
  );

  const goToLearningTrackTaskReviewerComment = useCallback(
    (targetTaskId, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId) return;
      goTo(
        generatePath(RouteTemplates.myLearningTrackTaskReviewerComment, {
          alias,
          trackId,
          trackStepId,
          courseId,
          stageId,
          taskId: targetTaskId,
        }),
        options,
      );
    },
    [alias, trackId, trackStepId, courseId, stageId, goTo],
  );

  const goToLearningTrackPoll = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId || !catalog) return;
      const path = compile(RouteTemplates.myLearningTrackPoll, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId,
        catalog,
        pollId: id,
      });
      goTo(path, options);
    },
    [alias, trackId, trackStepId, catalog, courseId, goTo, stageId],
  );

  const goToLearningTrackPollScreen = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId || !catalog || !pollId) return;
      const path = compile(RouteTemplates.myLearningTrackPollScreen, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId,
        catalog,
        pollId,
        screenId: id,
      });
      goTo(path, options);
    },
    [alias, trackId, trackStepId, catalog, courseId, goTo, pollId, stageId],
  );

  const goToLearningTrackPollFinish = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId || !catalog || !pollId) return;
      const path = compile(RouteTemplates.myLearningTrackPollFinishScreen, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId,
        catalog,
        pollId,
      });
      goTo(path, options);
    },
    [alias, trackId, trackStepId, catalog, courseId, goTo, pollId, stageId],
  );

  const goToLearningTrackPollResult = useCallback(
    (options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId || !catalog || !pollId) return;
      const path = compile(RouteTemplates.myLearningTrackPollResultScreen, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId,
        catalog,
        pollId,
      });
      goTo(path, options);
    },
    [alias, trackId, trackStepId, catalog, courseId, goTo, pollId, stageId],
  );

  const goToLearningTrackCourseBooking = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId || !catalog) return;
      const path = compile(RouteTemplates.myLearningTrackCourseBooking, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId,
        catalog,
        bookingId: id,
      });
      goTo(path, options);
    },
    [alias, trackId, trackStepId, catalog, courseId, goTo, stageId],
  );

  const goToLearningTrackCourseBookingRegistrations = useCallback(
    (id: string, options: IOptions = defaultOptions) => {
      if (!alias || !trackId || !trackStepId || !courseId || !stageId || !catalog) return;
      const path = compile(RouteTemplates.myLearningTrackCourseBookingRegistrations, { validate: false })({
        alias,
        trackId,
        trackStepId,
        courseId,
        stageId,
        catalog,
        bookingId: id,
      });
      goTo(path, options);
    },
    [alias, trackId, trackStepId, catalog, courseId, goTo, stageId],
  );

  const goToStage = useCallback(
    (stage: TStage, options: IOptions = defaultOptions) => {
      if (!alias || !courseId || stage.id === Number(stageId)) return;
      if (stage.taskId) {
        if (!!trackId && !!trackStepId) {
          goToLearningTrackTask(stage.id, stage.taskId, options);
        } else {
          goToStageTask(stage.id, stage.taskId, options);
        }
        return;
      }
      if (stage.scormId) {
        if (!!trackId && !!trackStepId) {
          goToLearningTrackScorm(stage.id, stage.scormId, options);
        } else {
          goToScorm(stage.id, stage.scormId, options);
        }
        return;
      }
      if (stage.catalogId) {
        if (!!trackId && !!trackStepId) {
          goToLearningTrackCatalog(stage.id, [stage.catalogId], options);
        } else {
          goToCatalog(stage.id, [stage.catalogId], options);
        }
      }
    },
    [
      alias,
      courseId,
      goToCatalog,
      goToLearningTrackCatalog,
      goToLearningTrackScorm,
      goToLearningTrackTask,
      goToScorm,
      goToStageTask,
      stageId,
      trackId,
      trackStepId,
    ],
  );

  const goToMyTasks = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myTasks, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyTasksActive = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myTasksActive, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyTasksCompleted = useCallback(() => {
    if (!alias) return;
    history.push(
      generatePath(RouteTemplates.myTasksCompleted, {
        alias,
      }),
    );
  }, [alias, history]);

  const goToMyTask = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.myTask, { validate: false })({
        alias,
        taskId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const goToMyTaskAnswers = useCallback(
    (id: number, options: IOptions = defaultOptions) => {
      if (!alias || !id) return;
      const path = compile(RouteTemplates.myTaskAnswers, { validate: false })({
        alias,
        taskId: id,
      });
      goTo(path, options);
    },
    [alias, goTo],
  );

  const goToMyTaskFinished = useCallback(
    (id: number) => {
      if (!alias || !id) return;
      history.push(
        generatePath(RouteTemplates.myTaskFinished, {
          alias,
          taskId: id,
        }),
      );
    },
    [alias, history],
  );

  const goToMyTaskReviewerComment = useCallback(
    (id: number) => {
      if (!alias || !id) return;
      history.push(
        generatePath(RouteTemplates.myTaskReviewerComment, {
          alias,
          taskId: id,
        }),
      );
    },
    [alias, history],
  );

  const memoParams = useMemo(
    () => ({
      alias: String(alias),
      catalog: catalog || '',
      courseId: Number(courseId || 0),
      pollId: Number(pollId || 0),
      stageId: Number(stageId || 0),
      screenId: Number(screenId || 0),
      coursesStatus: String(coursesStatus),
      scormId: Number(scormId || 0),
      prizeId: Number(prizeId || 0),
      stepId: Number(stepId || 1),
      groupId: Number(groupId || 0),
      bookingId: String(bookingId),
      taskId: Number(taskId || 0),
      frameSlug: String(frameSlug),
      learningTracksStatus: String(learningTracksStatus),
      trackId: String(trackId || ''),
      trackStepId: String(trackStepId || ''),
    }),
    [
      alias,
      catalog,
      courseId,
      pollId,
      stageId,
      screenId,
      coursesStatus,
      scormId,
      stepId,
      groupId,
      prizeId,
      bookingId,
      taskId,
      frameSlug,
      learningTracksStatus,
      trackId,
      trackStepId,
    ],
  );

  const memoPath = useMemo(
    () => ({
      myTeam: myTeamPath,
      myTeamActivity: myTeamActivityPath,
      getMyTeamGroupPath,
      getMyTeamGroupActivityPath,
      getMyTeamGroupCoursePath,
      getMyTeamGroupCoursesPath,
      getMyTeamGroupTracksPath,
      getMyTeamGroupTrackPath,
      getMyTeamGroupTrackCoursePath,
    }),
    [
      getMyTeamGroupActivityPath,
      getMyTeamGroupCoursePath,
      getMyTeamGroupCoursesPath,
      getMyTeamGroupTracksPath,
      getMyTeamGroupTrackPath,
      getMyTeamGroupPath,
      myTeamActivityPath,
      myTeamPath,
      getMyTeamGroupTrackCoursePath,
    ],
  );

  return useMemo(
    () => ({
      params: memoParams,
      path: memoPath,
      goToAlias,
      goToAllCourses,
      goToCourse,
      goToStage,
      goToCatalog,
      goToPoll,
      goToPollScreen,
      goToPollFinish,
      goToPollResult,
      goToScorm,
      goToStageTask,
      goToStageTaskFinished,
      goToStageTaskAnswers,
      goToStageTaskReviewerComment,
      goToCoursesSection,
      goToAuthSignIn,
      goToAuthSignUp,
      goToAuthResetPassword,
      goToGamificationRatings,
      goToGamificationRatingsDetails,
      goToGamificationRules,
      goToGamificationPrizes,
      goToGamificationPrize,
      goToGroupGamificationPrize,
      goToGroupGamificationPrizes,
      goToGroupGamificationRules,
      goToGroupGamificationRatings,
      goToGroupGamificationRatingsDetails,
      gamificationRulesPath,
      groupGamificationRulesPath,
      goToMyTeam,
      goToMyTeamGroup,
      goToMyTeamActivity,
      goToMyTeamGroupActivity,
      goToMyTeamGroupCourses,
      goToMyTeamGroupCourse,
      goToMyTeamGroupTracks,
      goToMyTeamGroupTrack,
      goToMyPolls,
      goToMyPollsActive,
      goToMyPollsFinished,
      goToMyPoll,
      goToMyPollScreen,
      goToMyPollFinish,
      goToMyPollResult,
      goToMenuPoll,
      goToMenuPollScreen,
      goToMenuPollFinish,
      goToMenuPollResult,
      goToMenuCatalog,
      goToMenuCatalogPoll,
      goToMenuCatalogPollScreen,
      goToMenuCatalogPollFinish,
      goToMenuCatalogPollResult,
      goToMyBookings,
      goToMyBookingCurrent,
      goToMyBookingAvailable,
      goToMyBooking,
      goToMyBookingRegistrations,
      goToMenuCatalogBookingRegistrations,
      goToMenuCatalogBooking,
      goToCourseBooking,
      goToCourseBookingRegistrations,
      goToLearningTracksSection,
      goToLearningTrack,
      goToLearningTrackCourse,
      goToLearningTrackStage,
      goToLearningTrackCatalog,
      goToLearningTrackScorm,
      goToLearningTrackTask,
      goToLearningTrackTaskFinished,
      goToLearningTrackTaskAnswers,
      goToLearningTrackTaskReviewerComment,
      goToLearningTrackPoll,
      goToLearningTrackPollScreen,
      goToLearningTrackPollFinish,
      goToLearningTrackPollResult,
      goToLearningTrackCourseBooking,
      goToLearningTrackCourseBookingRegistrations,
      goToMyTasks,
      goToMyTasksActive,
      goToMyTasksCompleted,
      goToMyTask,
      goToMyTaskAnswers,
      goToMyTaskFinished,
      goToMyTaskReviewerComment,
    }),
    [
      memoParams,
      memoPath,
      goToAlias,
      goToAllCourses,
      goToCourse,
      goToStage,
      goToCatalog,
      goToPoll,
      goToPollScreen,
      goToPollFinish,
      goToPollResult,
      goToScorm,
      goToStageTask,
      goToStageTaskFinished,
      goToStageTaskAnswers,
      goToStageTaskReviewerComment,
      goToCoursesSection,
      goToAuthSignIn,
      goToAuthSignUp,
      goToAuthResetPassword,
      goToGamificationRatings,
      goToGamificationRatingsDetails,
      goToGamificationRules,
      goToGamificationPrizes,
      goToGamificationPrize,
      goToGroupGamificationPrize,
      goToGroupGamificationPrizes,
      goToGroupGamificationRules,
      goToGroupGamificationRatings,
      goToGroupGamificationRatingsDetails,
      gamificationRulesPath,
      groupGamificationRulesPath,
      goToMyTeam,
      goToMyTeamGroup,
      goToMyTeamActivity,
      goToMyTeamGroupActivity,
      goToMyTeamGroupCourses,
      goToMyTeamGroupCourse,
      goToMyTeamGroupTracks,
      goToMyTeamGroupTrack,
      goToMyPolls,
      goToMyPollsActive,
      goToMyPollsFinished,
      goToMyPoll,
      goToMyPollScreen,
      goToMyPollFinish,
      goToMyPollResult,
      goToMenuPoll,
      goToMenuPollScreen,
      goToMenuPollFinish,
      goToMenuPollResult,
      goToMenuCatalog,
      goToMenuCatalogPoll,
      goToMenuCatalogPollScreen,
      goToMenuCatalogPollFinish,
      goToMenuCatalogPollResult,
      goToMyBookings,
      goToMyBookingCurrent,
      goToMyBookingAvailable,
      goToMyBooking,
      goToMyBookingRegistrations,
      goToMenuCatalogBookingRegistrations,
      goToMenuCatalogBooking,
      goToCourseBooking,
      goToCourseBookingRegistrations,
      goToLearningTracksSection,
      goToLearningTrack,
      goToLearningTrackCourse,
      goToLearningTrackStage,
      goToLearningTrackCatalog,
      goToLearningTrackScorm,
      goToLearningTrackTask,
      goToLearningTrackTaskFinished,
      goToLearningTrackTaskAnswers,
      goToLearningTrackTaskReviewerComment,
      goToLearningTrackPoll,
      goToLearningTrackPollScreen,
      goToLearningTrackPollFinish,
      goToLearningTrackPollResult,
      goToLearningTrackCourseBooking,
      goToLearningTrackCourseBookingRegistrations,
      goToMyTasks,
      goToMyTasksActive,
      goToMyTasksCompleted,
      goToMyTask,
      goToMyTaskAnswers,
      goToMyTaskFinished,
      goToMyTaskReviewerComment,
    ],
  );
};

export default useAppNavigator;

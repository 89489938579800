import styled from 'styled-components';
import { MIXINS, Grid } from '_constants';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as FullScreen } from 'assets/icons/fullscreen.svg';

export const CatalogImageOverlay = styled.div`
  position: relative;
  margin-bottom: 24px;
  ${MIXINS.ratio16x9}
  & .ant-image {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  & .ant-image:not(.ant-image-error) {
    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  & .ant-image-error {
    pointer-events: none;

    &::before,
    &::after {
      display: none;
    }

    & .ant-image-img {
      object-fit: cover;
    }
  }

  & .ant-image:not(.ant-image-error) {
    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
`;

export const FullScreenIcon = styled(FullScreen)`
  position: absolute;
  display: block;
  right: 10px;
  bottom: 10px;
  padding: 9px;
  z-index: 8;
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.mask.weaker};
  backdrop-filter: blur(10px);
  pointer-events: none;
  border-radius: 6px;
  @media (hover: hover) {
    display: none;
  }
`;

export const Close = styled(CloseIcon)`
  margin-left: 16px;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 24px;
  }
`;

export const CatalogElementWrapper = styled.div`
  margin-top: 32px;
  @media (max-width: ${Grid.MD}px) {
    margin-top: 24px;
  }
`;

enum Grid {
  XXS = 420,
  XS = 568,
  MD = 767,
  MLG = 809,
  LG = 1100,
  XL = 1280,
  XXL = 1330,
  XXXL = 1437,
  XXXXL = 1920,
}

export default Grid;

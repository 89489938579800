import { TResponded } from 'types';

export enum AuthorizationStatus {
  REGISTERED = 'registered',
  DECLINED = 'declined',
  PREMODERATION = 'premoderation',
  BLOCKED_AUTH_BY_EMAIL = 'blockedAuthByEmail',
  BLOCKED_AUTH_BY_PHONE = 'blockedAuthByPhone',
}

export type TSignInResponse = TResponded<{
  id: number;
  token: string | null;
  status: AuthorizationStatus;
  autoLogin: string;
}>;

export enum AuthCodeExchangeStatus {
  SUCCESS = 'success',
  PREMODERATION = 'premoderation',
  BLOCKED = 'blocked',
}

export type TAuthCodeExchangeResponse = TResponded<{
  attendeeId: number;
  token: {
    accessToken: string | null;
  };
  status: AuthCodeExchangeStatus;
}>;

export enum ContactType {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum RestoreStatus {
  SUCCESS = 'success',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  NOT_FOUND = 'userNotFound',
  BLOCKED_RESTORE_BY_EMAIL = 'blockedRestoreByEmail',
  BLOCKED_RESTORE_BY_PHONE = 'blockedRestoreByPhone',
}

export enum LimitStatus {
  SMS_LIMIT_EXCEEDED = 'smsLimitExceeded',
}

export type TRestoreResponse = TResponded<{
  code?: string;
  message?: string;
  success?: boolean;
  status?: RestoreStatus;
  sendType?: ContactType;
  blockedForSeconds?: number;
  sentTo?: string;
  limitStatus?: LimitStatus;
}>;

export type TUserAgreedResponse = TResponded<{
  agreement?: {
    acceptanceDate: string | null;
    createdDateUtc: string | null;
    id: number | null;
    speakerId: number | null;
  };
}>;
